import React, { Component } from 'react';
import './About.css';
//import Resume from '/Users/karthikdevarakonda/Desktop/Application:Profile Materials';

export class About extends Component {
    static displayName = About.name;

    render() {
        return (
            <div>
                <section id="about">
                </section>

                <div>
                    <h1 id="aboutHeader">
                        <b>About Me</b>
                    </h1>

                    <p className="aboutBlurbs" id="firstAbt"> <b>Currently a Software Engineer at CUNA Mutual Group</b> </p>

                    <p className="aboutBlurbs" id="secondAbt"><b>Studied Computer Science and Neurobiology
                        at the University of Wisconsin-Madison</b> </p>

                    <p className="aboutBlurbs" id="thirdAbt"><b>Graduated in May 2022!</b></p>

                    <p className="aboutBlurbs" id="fourthAbt">

                        <b>Hoping to work with Biotechnology in the future</b>
                    </p>

                    <p className="aboutBlurbs" id="fifthAbt">
                        <b>I'm eager to expand my developer repertoire and skillset</b></p>

                    <p className="aboutBlurbs" id="sixthAbt"><b>Ready to hit the ground running for any new challenge that comes my way!</b></p>


                </div>


            </div>
        );
    }
}
