import React, { Component } from 'react';
import './Contact.css';
import Resume from '../Resume_Official.pdf';
import github from '../github.png';
import linkedin from '../linkedin.png';
import mail from '../mail.png';

export class Contact extends Component {
    static displayName = Contact.name;

    render() {
        return (
            <div id="contact">
                <h1 id="contactHeader">
                    Contact Me
                </h1>

                <p id="contactBlurb1">
                    If there's anything else you'd like to know, please feel free to reach out
                </p>

                <p id="contactBlurb2">
                    Open for new  opportunities, always looking to learn
                </p>

                <button className="emailButton"><a href="mailto:kdev792@gmail.com" target="_blank"><b>Get in Touch</b></a></button>


                <div orientation="left" className="socialMediaOne">
                    <ul className="social-icons-contacts" fade-enter-done>
                        <li><a href="mailto:kdev792@gmail.com" target="_blank"><img src={mail} alt="mail" id="mail" /></a></li>
                        <li><a href="https://www.linkedin.com/in/karthik-devarakonda-a49a12177/" target="_blank"><img src={linkedin} alt="linkedin" id="linkedin" /></a></li>
                        <li><a href="https://github.com/kdev792" target="_blank"><img src={github} alt="github" id="github" /></a></li>

                    </ul>
                </div>

                <div orientation="right" className="bottomResume">
                    <div className="bottomResumeOne" fade-enter-done>
                        <a href={Resume} download="KarthikD_Resume" target="_blank">RESUME</a> 
                    </div>
                </div>

                <footer>

                </footer>

                
            </div>
        );
    }
}


