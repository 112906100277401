import React, { Component } from 'react';
import './Projects.css';

export class Projects extends Component {
    static displayName = Projects.name;

    render() {
        return (
            <div id="projects">
                <h1 id="projectsHeader">
                    Projects
                </h1>

                <a href="https://github.com/kdev792/B-Tree" target="_blank">
                    <button className="projectButtons" id="bTreeButton">
                        <p className="projectHeaders" id="bTreeHeader">
                            <b>B-Tree</b>
                        </p>
                    </button>
                </a>

                <a href="https://github.com/kdev792/AirportFullStack" target="_blank">
                    <button className="projectButtons" id="airportButton">
                        <p className="projectHeaders" id="airportHeader">
                            <b>Airport Optimizer</b>
                        </p>
                    </button>
                </a>

                <a href="https://github.com/kdev792/LiveWithMe" target="_blank">
                    <button className="projectButtons" id="livingButton">
                        <p className="projectHeaders" id="livingHeader">
                            <b>Live With Me</b>
                        </p>
                    </button>
                </a>

                <a href="https://github.com/kdev792/HeapAllocator" target="_blank">
                    <button className="projectButtons" id="heapButton">
                        <p className="projectHeaders" id="heapHeader">
                            <b>Heap Allocator</b>
                        </p>
                    </button>
                </a>
   
            </div>
        );
    }
}


