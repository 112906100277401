import React, { Component } from 'react';
//import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
//import { HashLink as Link} from 'react-router-hash-link';
import './NavMenu.css';
import './Home.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;


  constructor (props) {
      super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

    render() {
      return (
          <header className="header">
              <nav className="navBar">
                <div className="navDiv1">
                    <ul>
                        <li>
                              <a href="#home" className="NavTab" smooth spy to="#home"><button class="navButtons" id="homeButton">Home</button></a>
                        </li>
                        <li>
                              <a href="#about" className="NavTab" smooth spy to="#about"><button class="navButtons" id="aboutButton">About</button></a>
                        </li>
                        <li>
                              <a href="#skills" className="NavTab" smooth spy to="#skills"><button class="navButtons" id="skillsButton">Skills</button></a>
                        </li>
                        <li>
                              <a href="#projects" className="NavTab" smooth spy to="#projects"><button class="navButtons" id="projectsButton">Projects</button></a>
                        </li>
                        <li>
                              <a href="#contact" className="NavTab" smooth spy to="#contact"><button id="contactButton">Contact Me</button></a>
                        </li>
                    </ul>

                </div>
            </nav>
      </header>
    );
  }
}


//<Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
//    <NavbarBrand tag={Link} to="/">Karthik Devarakonda</NavbarBrand>
//    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
//        <ul className="navbar-nav flex-grow">
            //<NavItem>
            //    <NavLink tag={Link} className="text-dark" smooth to="#home">Home</NavLink>
            //</NavItem>
            //<NavItem>
            //    <NavLink tag={Link} className="text-dark" smooth to="#counter">Counter</NavLink>
            //</NavItem>
            //<NavItem>
            //    <NavLink tag={Link} className="text-dark" smooth to="#fetch-data">Fetch data</NavLink>
            //</NavItem>
//        </ul>
//    </Collapse>
//</Navbar>