import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Home } from './Home';
import { About } from './About';
import { Skills } from './Skills';
import './Layout.css';
import { Projects } from './Projects';
import { Contact } from './Contact';


export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
        <body id="layoutBody">
            <div>
                <NavMenu />
                <section className="portSections">
                    
                    <Home />

                </section>

                <div className="pageSeparators"></div>

                <section className="portSections">
                    <About />
                </section>

                <div className="pageSeparators"></div>

                <section className="portSections">
                    <Skills/>
                </section>

                <div className="pageSeparators"></div>

                <section className="portSections">
                    <Projects/>
                </section>

                <div className="pageSeparators"></div>

                <section className="portSections">
                    <Contact />
                </section>
            </div>
        </body>
    );
  }
}
