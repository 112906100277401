import React, { Component } from 'react';
import './Home.css';
import { init } from 'ityped';
import Typewriter from "typewriter-effect";
import Resume from '../Resume_Official.pdf';
import backGround from '../backGround.jpg';
import github from '../github.png';
import linkedin from '../linkedin.png';


export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <body className="bodyProp">
                <div id="home">
                    <div className="headerDiv">
                        <h1 id="hello">Hey, I'm</h1>
                        <h1 id="mainHeader"><b>Karthik Devarakonda</b></h1>
                    </div>

                    <div>
                        <p id="imA">and I'm a...</p>
                    </div>

                    <div className="typewriter">
                        <p id="typer">
                            <Typewriter

                                options={{
                                    strings: ['Software Engineer', 'Data Scientist', 'Researcher', 'Consultant', 'Full-Stack Developer'],
                                    autoStart: true,
                                    loop: true,
                                    pauseFor: 1200
                                }}
                            />
                        </p>
                    </div>

                    <div>
                        <img id="homePic" src={backGround} alt="background" height="300" width="400" />
                    </div>


                </div>

            </body>

          
        );
    }
}


