import React, { Component } from 'react';
import './Skills.css';
import '../tech-icons/dist/icons.css';
import html5 from '../html5.png';
import css from '../css3.png';
import android from '../androidOS.png';
import azure from '../azure.png';
import mysql from '../mysql.png';



export class Skills extends Component {
    static displayName = Skills.name;

    render() {
        return (
            <div id="skills">
                <h1 id="skillsHeader">
                    Skills
                </h1>


                <button className="skillsButtons" id="pythonButton" title="Python"><i class='icon-tech-python'></i> Python</button>

                <button className="skillsButtons" id="angularButton"><i class='icon-tech-angular'></i> AngularJS</button>

                <button className="skillsButtons" id="agileButton"><i class='icon-tech-agile'></i> Agile</button>

                <button className="skillsButtons" id="bashButton"><i class='icon-tech-bash'></i> Bash</button>

                <button className="skillsButtons" id="cppButton"><i class='icon-tech-cplusplus'></i> C++</button>

                <button className="skillsButtons" id="cSharpButton"><i class='icon-tech-csharp'></i> C#</button>

                <button className="skillsButtons" id="dockerButton"><i class='icon-tech-docker'></i> Docker</button>

                <button className="skillsButtons" id="gitButton"><i class='icon-tech-git'></i> Git</button>

                <button className="skillsButtons" id="javaButton"><i class='icon-tech-java'></i> Java</button>

                <button className="skillsButtons" id="javascriptButton"><i class='icon-tech-javascript'></i> JavaScript</button>

                <button className="skillsButtons" id="netButton"><i class='icon-tech-net'></i> .NET</button>

                <button className="skillsButtons" id="nodejsButton"><i class='icon-tech-nodejs'></i> Node.js</button>

                <button className="skillsButtons" id="powershellButton"><i class='icon-tech-powershell'></i> PowerShell</button>

                <button className="skillsButtons" id="rButton"><i class='icon-tech-r'></i> R</button>

                <button className="skillsButtons" id="reactButton"><i class='icon-tech-react'></i> ReactJS</button>

                <button className="skillsButtons" id="sqlButton"><i class='icon-tech-sql'></i> SQL</button>

                <button className="skillsButtons" id="typescriptButton"><i class='icon-tech-typescript'> TypeScript</i></button>

                <button className="skillsButtons" id="unityButton"><i class='icon-tech-unity'></i> Unity</button>

                <button className="skillsButtons" id="vueButton"><i class='icon-tech-vue'></i> VueJS</button>

                <button className="skillsButtons" id="sqlServerButton"><i class='icon-tech-sql-server'></i> SQLServer</button>

                <button className="skillsButtons" id="html5Button"><img src={html5} alt='HTML5' /> HTML5</button>

                <button className="skillsButtons" id="cssButton"><img src={css} alt='CSS3' /> CSS3</button>

                <button className="skillsButtons" id="androidOSButton"><img src={android} alt='AndroidStudio' /> AndroidOS</button>

                <button className="skillsButtons" id="azureButton"><img src={azure} alt='Azure' /> Azure</button>

                <button className="skillsButtons" id="mySQLButton"><img src={mysql} alt='MySQL' /> MySQL</button>





            </div>
        );
    }
}

