import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { Home } from './components/Home';
import { NavMenu } from './components/NavMenu';
import './components/NavMenu.css';
import Login from './components/Login.js';


export default class App extends Component {
  static displayName = App.name;

  render() {
      return (

        <body>
            <Layout>
                  <Router>
                      <Routes>
                          <Route path="/Login" element={<Login />} />
                      </Routes>
                  </Router>
            </Layout>
        </body>
    );
  }
}
