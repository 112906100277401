import React, { Component } from 'react';

export default class Login extends Component {
    static displayName = Login.name;

    render() {
        return (
            <body id="login">
                    
            </body>
        );
    }
}
